<template>
  <div v-if="loadedPage" class="entity-editor__lock-manager-container">
    <div class="phone-nrs-container">
      <InputField
        v-if="!availableForPhoneNrs.length"
        v-model="setLocked"
        inputType="checkbox"
        label="Locked"/>
      <div v-else>
        <b>Locked for everyone else</b>
      </div>
      <div
        v-if="availableForPhoneNrs.length"
        class="phone-nrs-list">
        <div
          v-for="(phoneNr, index) in availableForPhoneNrs"
          :key="index"
          class="phone-nr-item">
          {{ phoneNr }}
          <iconComponent
            symbol="cross"
            width="16"
            @click="removePhoneNr(phoneNr)"/>
        </div>
      </div>
      <div
        v-if="availableForPhoneNrs.length"  
        class="phone-nrs-length">
        Total: {{ availableForPhoneNrs.length }}
      </div>
    </div>
    <div class="add-phone-nr">
      <InputField
        v-model="phoneNr"
        placeholder="+46xxxxxxxxx"/>
      <button class="btn btn-activate" @click="addPhoneNr">Unlock</button>
    </div>
  </div>
</template>

<script>
import InputField from '../InputField/InputField.vue';
// import AutoComplete from '../AutoComplete/AutoComplete.vue';

export default {
  name: 'LockManagerComponent',
  components: {
    InputField,
  },
  props: {
    activation: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    userType: {
      type: String,
      required: false,
      default: 'Admin',
    },
  },
  mounted() {
    if (this.activation.available_for_phone_nrs.includes('locked')) {
      this.setLocked = true;
    }
  },
  watch: {
    addThesePhoneNr: {
      handler() {
        this.emitPhoneNrChanges();
      },
      deep: true,
    },
    removeThesePhoneNr: {
      handler() {
        this.emitPhoneNrChanges();
      },
      deep: true,
    },
    setLocked: {
      handler() {
        this.emitPhoneNrChanges();
      },
      deep: true,
    },
    availableForPhoneNrs: {
      handler() {
        this.setLocked = true;
      },
      deep: true,
    },
    'activation.available_for_phone_nrs': {
      handler() {
        if (this.activation.available_for_phone_nrs.includes('locked')) {
          this.setLocked = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    availableForPhoneNrs() {
      let availableForPhoneNrs = (this.activation.available_for_phone_nrs || []);

      // filter out removeThesePhoneNr
      availableForPhoneNrs = availableForPhoneNrs.filter((phoneNr) => !this.removeThesePhoneNr.includes(phoneNr));
      // add addThesePhoneNr
      availableForPhoneNrs = availableForPhoneNrs.concat(this.addThesePhoneNr);
      // filter out locked
      availableForPhoneNrs = availableForPhoneNrs.filter((phoneNr) => phoneNr != 'locked');

      return availableForPhoneNrs;
    }
  },
  data() {
    return {
      loadedPage: true,
      phoneNr: '',
      addThesePhoneNr: [],
      removeThesePhoneNr: [],
      setLocked: false,
    };
  },
  methods: {
    addPhoneNr() {
      const index = this.availableForPhoneNrs.indexOf(this.phoneNr);
      if (this.phoneNr && this.phoneNr.includes('+') && index === -1) {
        this.addThesePhoneNr.push(this.phoneNr);
        this.phoneNr = '';
      } else if (index > -1) {
        this.setAlert('Phone number already added');
      } else {
        this.setAlert('Please enter a valid phone number');
      }
    },
    removePhoneNr(phoneNr) {
      const index = this.addThesePhoneNr.indexOf(phoneNr);
      if (index > -1) {
        this.addThesePhoneNr.splice(index, 1);
      } else {
        this.removeThesePhoneNr.push(phoneNr);
      }
    },
    emitPhoneNrChanges() {
      const addThesePhoneNr = [...this.addThesePhoneNr, ...(this.setLocked ? ['locked'] : [])];
      const removeThesePhoneNr = [...this.removeThesePhoneNr, ...(!this.setLocked ? ['locked'] : [])];
      this.$emit('updatePhoneNrs', {
        addThesePhoneNr,
        removeThesePhoneNr,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './LockManager.scss';
</style>
