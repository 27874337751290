<template>
  <div v-if="user && user._id" class="user-profile">
    <div class="--container">
      <div class="--gradient-header"
           :style="`background-image: url(${require('../assets/images/enginio_header_desktop.png')});`">
        <h1 class="--desktop">
          {{ user.name }} {{ user.last_name }}
        </h1>
        <h2 class="--mobile">
          {{ user.name }} {{ user.last_name }}
        </h2>
        <div class="--icon-container --mobile">
          <iconComponent class="--icon" width="16px" @click="$router.push({ name: 'UserSettings'})"
                         stroke="#111111" fill="#111111" symbol="gear" />
        </div>


        <div class="btn btn-medium btn-whitebg-border --desktop"
             @click="$router.push({ name: 'UserSettings'})">
          <iconComponent class="--icon" width="16px" stroke="#EA7801" fill="#EA7801" symbol="gear" />
          <div class="--text">
            {{ transl.UserSettings.accountsettings }}
          </div>
        </div>
      </div>

      <div v-if="!user.verified_email" class="--verify-email" @click="$router.push({ name: 'UserEmail' })">
        <div class="--text button-text">{{ transl.UserProfile.noteverify }}</div>
        <iconComponent class="--icon" width="14px" stroke="#DB4800" fill="#DB4800" symbol="emailWithPlus" />
      </div>

      <div v-else class="--hidden">
        <div class="--text button-text">{{ transl.UserProfile.noteverify }}</div>
        <iconComponent class="--icon" width="14px" stroke="#DB4800" fill="#DB4800" symbol="emailWithPlus" />
      </div>

      <div class="--user-activations" v-if="clickedMenu == ''">
        <!--<div class="pre-title-small">{{ transl.Company.activations }}</div>
        <div tag="div" class="--mobile --activation"
             @click="$router.push({ name: 'UserActivations'}); clickedMenu = 'activation'">
          <iconComponent class="--icon" width="21px" stroke="#DB4800" fill="#DB4800" symbol="confetti" />
          <div class="button-text">{{ transl.UserProfile.myactivations }}</div>
        </div>-->
        <div class="--activation --mobile" @click="$router.push({ name: 'UserHistory'}); clickedMenu = 'history'">
          <iconComponent class="--icon" width="21px" stroke="#DB4800" fill="#DB4800" symbol="paperRoll" />
          <div class="button-text">{{ transl.UserProfile.myhistory }}</div>
        </div>
        <div>
        </div>
      </div>

      <!--<router-link @click.native="clickedMenu = 'activation'" tag="div" :to="{ name: 'UserActivations' }"
                   id="my-activation" class="--desktop --activation">
        <iconComponent class="--icon" width="21px"
                       :stroke="clickedMenu === 'activation' ? '#FFFFFF' : '#DB4800'"
                       :fill="clickedMenu === 'activation' ? '#FFFFFF' : '#DB4800'" symbol="confetti" />
        <div class="button-text">{{ transl.UserProfile.myactivations }}</div>
      </router-link>-->
      <div></div>

      <router-link @click.native="clickedMenu = 'history'" tag="div" :to="{ name: 'UserHistory' }"
                   class="--activation --desktop --history">
        <iconComponent class="--icon" width="21px" :stroke="clickedMenu === 'history' ? '#FFFFFF' : '#DB4800'"
                       :fill="clickedMenu === 'history' ? '#FFFFFF' : '#DB4800'" symbol="paperRoll" />
        <div class="button-text">{{ transl.UserProfile.myhistory }}</div>
      </router-link>

      <div class="pre-title-small --user-account-header --desktop">
        {{ transl.UserProfile.youraccount }}
      </div>

      <router-link
        @click.native="clickedMenu = 'shared'"
        tag="div"
        :to="{ name: 'UserSharedData' }"
                   class="--activation --desktop --shared ">
        <iconComponent class="--icon" width="21px" :stroke="clickedMenu === 'shared' ? '#FFFFFF' : '#DB4800'"
                       :fill="clickedMenu === 'shared' ? '#FFFFFF' : '#DB4800'" symbol="userWithCheckboard" />
        <div class="button-text">{{ transl.UserProfile.mysharedinfo }}</div>
      </router-link>

      <div class="--user-account --mobile" v-if="clickedMenu == ''">
        <div class="pre-title-small">{{ transl.UserProfile.myhistory }}</div>
        <div class="--activation" @click="$router.push({ name: 'UserSharedData'}); clickedMenu = 'shared'">
          <iconComponent class="--icon" width="21px" stroke="#DB4800" fill="#DB4800"
                         symbol="userWithCheckboard" />
          <div class="button-text">{{ transl.UserProfile.mysharedinfo }}</div>
        </div>
      </div>

      <router-view v-if="clickedMenu !== ''" class="" @back="clickedMenu = ''"></router-view>

      <div class="--dashboard-desktop" v-if="clickedMenu === ''">
        <h3>{{ transl.UserSettings.hey }}</h3>
        <div class="pre-title-small">{{ transl.UserSettings.whattodo }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters, mapMutations } from 'vuex';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../mixins/AnnoyingClientMixin.vue';

export default {
  name: 'UserProfile',
  components: {},
  mixins: [ExpiredMixin, BeforeEnterMixin, AnnoyingClientMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle(this.transl.UserSettings.accountsettings);
    this.setCompany({});
    this.$store.dispatch('getUser');
    this.$store
      .dispatch('getActiveOffers')
      .then((activeOffers) => {
        this.activeOffers = activeOffers;
        if (this.activeOffers.length) {
          this.startExpiresTime();
        }

        this.setLoadedPageData(true);
        setTimeout(() => {
          let elements = document.getElementsByClassName('autoplayMedia');
          for (let i = 0; i < elements.length; i += 1) {
            // if (this.isAnnoyingClient) {
            //   // dont autoplay
            // } else {
            this.autoplay(elements[i]);
            // }
          }
        }, 200);
      });
  },
  watch: {},
  computed: {
    ...mapGetters(['user', 'userStatus']),
  },
  destroyed() {
    clearTimeout(this.timeInterval);
    this.$store.dispatch('getUser');
  },
  data() {
    return {
      info: {
        name: '',
        last_name: '',
      },
      navOption: 'favorites',
      activeOffers: [],
      timeInterval: null,
      expiresTime: [],
      clickedMenu: '',
    };
  },
  methods: {
    ...mapMutations({
      setFavorite: 'SET_FAVORITE',
      setCompany: 'COMPANY',
    }),
    gotoOffer(offerId) {
      this.$router.push({ name: 'UserOffer', params: { offerId } });
    },
    createFavorite(offerId, favorite) {
      this.$store
        .dispatch('setLocalFavoriteOffer', {
          offerId,
          favorite: !favorite,
        })
        .then(() => {
          this.setAlert(`${!favorite ? this.transl.UserProfile.addedfavorite : this.transl.UserProfile.nofavorite}`);
        });
    },
    startExpiresTime() {
      this.setExpires();
      this.timeInterval = setInterval(() => {
        this.setExpires();
      }, 1000);
    },
    setExpires() {
      this.expiresTime = [];
      for (let i = 0; i < this.activeOffers.length; i += 1) {
        const offer = this.activeOffers[i];
        const nowMoment = new moment().unix();
        if (nowMoment < (offer.active_expires_at || Infinity)) {
          this.expiresTime.push(this.getExpiresTime(offer.active_expires_at, false));
        } else if (nowMoment > (offer.active_expires_at || Infinity)) {
          this.activeOffers.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserProfile";
</style>
