<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <div v-if="user">
      <div class="admin-user-details">
        <div class="admin-user-detail">
          <div>Name</div><div>{{ user.name }}</div>
        </div>
        <div class="admin-user-detail">
          <div>Last name</div><div>{{ user.last_name }}</div>
        </div>
        <div class="admin-user-detail">
          <div>Email</div><div>{{ user.email }}</div>
        </div>
        <div class="admin-user-detail">
          <div>Registered to Enginio emails</div><div>{{ user.unregister_email ? 'no' : 'yes' }}</div>
        </div>
        <div class="admin-user-detail">
          <div>Phone Nr</div><div>{{ user.phone_nr }}</div>
        </div>
        <div class="admin-user-detail">
          <div>Street</div><div>{{ user.adress }}</div>
        </div>
        <div class="admin-user-detail">
          <div>Postal code</div><div>{{ user.postal_code }}</div>
        </div>
        <div class="admin-user-detail">
          <div>City</div><div>{{ user.city }}</div>
        </div>
        <div
           class="btn btn-activate"
           @click="deleteUser()">Delete user</div>
      </div>


      <tabs>
        <tab title="Active Activations">
          Active Activations
          <div
            v-for="activeOffer in user.active_offers"
            :key="`${activeOffer.offerId}active`"
            class="user-offers">
            <div class="user-offer">
              <div class="user-offer__id">Activation id</div><div>{{ activeOffer.offerId }}</div>
              <div class="user-offer__name">Activation name</div><div>{{ activeOffer.unique_name }}</div>
              <div class="btn btn-activate" @click="deleteUserOffer('active', activeOffer.offerId)">delete</div>
            </div>
          </div>
        </tab>
        <tab title="Unlocked Activations">
          Unlocked Activations
          <div
            v-for="unlockedOffer in user.unlocked_offers"
            :key="`${unlockedOffer._id}unlocked`"
            class="user-offers">
            <div class="user-offer">
              <div class="user-offer__id">Activation id</div><div>{{ unlockedOffer._id }}</div>
              <div class="user-offer__name">Activation name</div><div>{{ unlockedOffer.first_header }}</div>
              <div class="btn btn-activate" @click="deleteUserOffer('unlocked', unlockedOffer._id)">delete</div>
            </div>
          </div>
        </tab>
        <tab title="Used Activations">
          Used Activations
          <div
            v-for="usedOffer in user.used_offers"
            :key="`${usedOffer._id}used`"
            class="user-offers">
            <div class="user-offer">
              <div class="user-offer__id">Activation id</div><div>{{ usedOffer._id }}</div>
              <div class="user-offer__name">Activation name</div><div>{{ usedOffer.first_header }}</div>
              <div class="btn btn-activate" @click="deleteUserOffer('used', usedOffer._id)">delete</div>
            </div>
          </div>
        </tab>
        <tab title="Claimed Activations">
          Claimed Activations
          <div
            v-for="claimedOffer in user.claimed_offers"
            :key="`${claimedOffer._id}claimed`"
            class="user-offers">
            <div class="user-offer">
              <div class="user-offer__id">Activation id</div><div>{{ claimedOffer._id }}</div>
              <div class="user-offer__name">Activation name</div><div>{{ claimedOffer.first_header }}</div>
              <div class="btn btn-activate" @click="deleteUserOffer('claimed', claimedOffer._id)">delete</div>
            </div>
          </div>
        </tab>
        <tab title="Roles">
          Roles
          <div class="btn btn-activate" @click="roleModal = true">add role</div>
          <div
            v-for="role in user.roles"
            :key="role._id"
            class="user-offers">
            <div class="user-offer">
              <div class="user-offer__id">Role name</div><div>{{ role.name }}</div>
              <div class="user-offer__name">Tenant</div><div>{{ role.tenant }}</div>
              <div v-if="role.tenant == 'COMPANY'" class="user-offer__name">Company</div><div>{{ role.company?.name }}</div>
              <div class="btn btn-activate" @click="removeUserRole(role)">remove</div>
            </div>
          </div>
        </tab>
      </tabs>

      <modal v-if="roleModal" size="xlarge"
        @close="roleModal = false;" modalType="fullscreen">
        <slot>
          <div class="add-role-wrapper">
            <h3>Add role to {{ user.name }}</h3>
            <inputField
              v-model="newRole"
              label="Role name"
              inputType="select"
              :selectOptions="roles"></inputField>
            <inputField
              v-model="newTenant"
               label="Tenant"
              inputType="select"
              :selectOptions="tenants"></inputField>
            <AutoComplete
              v-if="newTenant == 'COMPANY'"
              :value="newCompany.name"
              :options="modalCompanies"
              :required="true"
              :is-async="true"
              unique-id="change"
              title="Brand"
              placeholder="Find Brand by name"
              resultPosition="relative"
              @input="findAdminCompanies($event)"
              @selected="newCompany = $event"/>
            <div class="btn btn-activate" @click="addUserRole()">Add</div>
          </div>
        </slot>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '../resources/eventBus';
import tabs from '../stories/components/Tabs/Tabs';
import tab from '../stories/components/Tabs/Tab';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import AutoComplete from '../stories/components/AutoComplete/AutoComplete.vue';

export default {
  name: 'AdminUser',
  components: {
    tabs,
    tab,
    InputField,
    AutoComplete,
  },
  props: ['userId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
    this.setPageTitle('Admin', 'User');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('users', ['user', 'usersStatus']),
    ...mapGetters(['modalCompanies']),
  },
  watch: {
    userId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    },
    newTenant(newVal) {
      if (newVal != 'COMPANY') {
        this.newCompany = {};
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      roleModal: false,
      newRole: '',
      newTenant: '',
      newCompany: {},
      // TODO: this should be fetched from backend
      roles: [
        'SUPER_ADMIN_ALL', 'SUPER_ADMIN_PUBLISH', 'SUPER_ADMIN_WRITE', 'SUPER_ADMIN_ASSET', 'SUPER_ADMIN_READ',
        'ADMIN_ALL', 'ADMIN_PUBLISH', 'ADMIN_WRITE', 'ADMIN_ASSET', 'ADMIN_READ',
        'PARTNER_ALL', 'PARTNER_PUBLISH', 'PARTNER_WRITE', 'PARTNER_ASSET', 'PARTNER_READ',
      ],
      tenants: ['GLOBAL', 'COMPANY'],
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('users/getAdminUser', { entityId: this.userId }).then(() => {
        this.loadedPage = true;
      });
    },
    findAdminCompanies(query) {
      this.$store.dispatch('findAdminCompanies', { query });
    },
    deleteUser() {
      this.$store.dispatch('users/adminDeleteUser', { userId: this.user._id }).then(() => {
        this.setAlert('Successfully deleted user!');
        this.$router.push({ name: 'AdminUsers' });
      });
    },
    deleteUserOffer(type, offerId) {
      this.$store.dispatch('users/adminDeleteUserOffer', { userId: this.user._id, offerId, type }).then(() => {
        this.setAlert('Successfully deleted active offer from user!');
      });
    },
    addUserRole() {
      if (this.newRole == '') {
        this.setAlert('You must select a role!');
      }

      this.$store.dispatch('users/adminAddUserRole', {
        userId: this.user._id,
        role: this.newRole,
        tenant: this.newTenant,
        companyId: this.newCompany.value,
      }).then(() => {
        this.setAlert('Successfully added role!');
        this.newRole = '';
      });
    },
    removeUserRole(role) {
      this.$store.dispatch('users/adminRemoveUserRole', { userId: this.user._id, role }).then(() => {
        this.setAlert('Successfully removed role!');
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminUser";
</style>
