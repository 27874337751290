/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import activationApi from '../../api/pvActivation.api';
import receiptApi from '../../api/pvReceipt.api';

// initial state
const state = {
  activation: {},
  activationQuery: '',

  activations: [],
  receipts: [],
  receiptsTotal: 0,
  modalPvActivations: [],
};

// getters
const getters = {
  activation: state => state.activation,
  activationQuery: state => state.activationQuery,

  activations: state => state.activations,
  receipts: state => state.receipts,
  receiptsTotal: state => state.receiptsTotal,
  modalPvActivations: state => state.modalPvActivations,
};

// actions
const actions = {
  // ADMIN
  findAdminPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.findAdminPVActivation(payload)
      .then((response) => {
        commit('ACTIVATIONS', response.activations);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  findAdminModalPVActivations({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.findAdminPVActivation(payload)
      .then((response) => {
        commit('MODAL_ACTIVATIONS', response.activations);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  addAdminPVActivation({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return activationApi.addAdminPVActivation(payload)
      .then((response) => {
        commit('ADD_ACTIVATION', response.company);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  addAdminPVActivationIcon({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.addAdminPVActivationIcon(payload)
      .then((response) => {
        commit('SAVE_ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return true;
      });
  },

  addAdminPVActivationPdf({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.addAdminPVActivationPdf(payload)
      .then((response) => {
        commit('SAVE_ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return true;
      });
  },

  addAdminPVActivationMedia({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.addAdminPVActivationMedia(payload)
      .then((response) => {
        commit('SAVE_ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return true;
      });
  },

  addAdminPVActivationThumbnail({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.addAdminPVActivationThumbnail(payload)
      .then((response) => {
        commit('SAVE_ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  saveAdminPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.saveAdminPVActivation(payload)
      .then((response) => {
        commit('SAVE_ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  publiciseAdminPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.publiciseAdminPVActivation(payload)
      .then((response) => {
        commit('SAVE_ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  addAdminActivationEmailAttachment({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.addAdminActivationEmailAttachment(payload)
      .then((response) => {
        commit('ACTIVATION_STATUS', 'FINISHED');
        return response.fileKey;
      });
  },

  cloneAdminPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.cloneAdminPVActivation(payload)
      .then((response) => {
        commit('ACTIVATION_STATUS', 'FINISHED');
        return response.activations.length;
      });
  },

  deleteAdminPVActivationPicture({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.deleteAdminPVActivationPicture(payload.entityId)
      .then(() => {
        commit('SAVE_ACTIVATION', payload.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return;
      });
  },

  deleteAdminPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.deleteAdminPVActivation(payload)
      .then(() => {
        commit('DELETE_ACTIVATION', payload.entityId);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return;
      });
  },

  getAdminPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return receiptApi.getAdminPVActivation(payload.activationId)
      .then((response) => {
        commit('ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return;
      });
  },

  findAdminAllReceipts({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return receiptApi.findAdminAllReceipts(payload)
      .then((response) => {
        commit('RECEIPTS', response.receipts);
        commit('RECEIPTS_TOTAL', response.receiptsTotal);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return;
      });
  },

  updateAdminReceiptByKey({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return receiptApi.updateAdminReceiptByKey(payload)
      .then((response) => {
        commit('UPDATE_RECEIPT', response.receipt);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return;
      });
  },

  // COMPANY
  findCompanyAllReceipts({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return receiptApi.findCompanyAllReceipts(payload)
      .then((response) => {
        commit('RECEIPTS', response.receipts);
        commit('RECEIPTS_TOTAL', response.receiptsTotal);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return;
      });
  },
  
  findCompanyPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.findCompanyPVActivation(payload)
      .then((response) => {
        commit('ACTIVATIONS', response.activations);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  getCompanyPVActivation({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return receiptApi.getCompanyPVActivation(payload.activationId)
      .then((response) => {
        commit('ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
        return;
      });
  }
};

// mutations
const mutations = {
  ACTIVATION(state, activation) {
    state.activation = activation;
  },
  OFFER(state, offer) {
    state.offer = offer;
  },
  RECEIPTS(state, receipts) {
    state.receipts = receipts;
  },
  RECEIPTS_TOTAL(state, receiptsTotal) {
    state.receiptsTotal = receiptsTotal;
  },
  ACTIVATIONS(state, activations) {
    state.activations = activations;
  },
  ADD_ACTIVATION(state, activation) {
    state.activations.unshift(activation);
  },
  UPDATE_RECEIPT(state, receipt) {
    for (let i = 0; i < state.receipts.length; i++) {
      const rec = state.receipts[i];
      if (rec._id == receipt._id) {
        Vue.set(state.receipts, i, receipt);
      }
    }
  },

  ACTIVATION_QUERY(state, activationQuery) {
    state.activationQuery = activationQuery;
  },
  ADD_CHILD_ACTIVATION(state, { activation, parentActivationId }) {
    if (state.activation._id === parentActivationId) {
      state.activation.childActivations.push(activation);
    }
  },
  SAVE_ACTIVATION(state, activation) {
    state.activation = activation;
  },
  DELETE_ACTIVATION(state) {
    state.activation = {};
  },
  ADD_OFFER(state, data) {
    state.activation.offers.push(data.offer);
  },
  ACTIVATION_STATUS(state, status) {
    state.activationStatus = status;
  },
  MODAL_ACTIVATIONS(state, activations) {
    state.modalPvActivations = [];
    for (let i = 0; i < activations.length; i += 1) {
      state.modalPvActivations.push({
        value: activations[i]._id,
        text: activations[i].name,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
