<template>
  <div
    v-if="loadedPage"
    class="review-list">

    <div
      class="the-list">
      <md-table>
        <md-table-toolbar class="list-toolbar">
          <div>
            <!--<h1 v-if="reviewsTotal" class="md-title">{{ reviewsTotal }} Reviews</h1>-->
            <h1 class="md-title">{{ reviews.length >= 50 ? '> 50' : reviews.length }} Reviews</h1>
          </div>
          <InputField
            v-model="search"
            placeholder="Find review..."
            trailingIcon="magnifyingGlass"
            clickableTrailingIcon
            @trailingIconClick="findAdminPVReviews()"
            @enterKey="findAdminPVReviews()"/>

          <div
            class="column-picker"
            @focus="activationOptions = true"
            @blur="activationOptions = false">
            <button
              class="btn btn-activate"
              @click="activationOptions = !activationOptions">
              Activations
            </button>
            <div
              v-show="activationOptions"
              class="column-options">
              <InputField
                v-model="actSearch"
                placeholder="Find activation..."
                class="column-search"
                @input="findAdminPVActivation()"/>
              <div
                class="column-option"
                v-for="(activation, index) in activationPicker"
                :class="{ 'active-column-option': activation.active }"
                :key="`column-option-${index}-${activation.name}`"
                @click="activateActivationFilter(activation)">
                {{ activation.company.name }} - {{ activation.name }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="statusOptions = true"
            @blur="statusOptions = false">
            <button
              class="btn btn-activate"
              @click="statusOptions = !statusOptions">
              Status
            </button>
            <div
              v-show="statusOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="status in statuses"
                :class="{ 'active-column-option': status.active }"
                :key="`column-option-${status.id}`"
                @click="activateStatusFilter(status)">
                {{ status.name }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="filterOptions = true"
            @blur="filterOptions = false">
            <button
              class="btn btn-activate"
              @click="filterOptions = !filterOptions">
              Filters
            </button>
            <div
              v-show="filterOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="filter in filters"
                :class="{ 'active-column-option': filter.active }"
                :key="`column-option-${filter.id}`">
                <div @click="filter.func ? filter.func() : filter.active = !filter.active">
                  {{ filter.name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="columnOptions = true"
            @blur="columnOptions = false">
            <button
              class="btn btn-activate"
              @click="columnOptions = !columnOptions">
              Columns
            </button>
            <div
              v-show="columnOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="column in columns"
                :class="{ 'active-column-option': column.active }"
                :key="`column-option-${column.title}`"
                @click="column.active = !column.active">
                {{ column.title }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="statOptions = true"
            @blur="statOptions = false">
            <button
              class="btn btn-activate"
              :class="{ 'btn-disabled': !activationId }"
              @click="statOptions = !statOptions">
              Stats
            </button>
            <div
              v-show="statOptions"
              class="column-options">
              <div class="column-option">
                NOTE: the search will not be applied to any of these lists (yet)
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="showListModal = true">Get List</button>
              </div>
            </div>
          </div>
        </md-table-toolbar>
        <!--style="position: sticky; top: 0; z-index: 1;" dosnt work..-->
        <md-table-row>
          <md-table-head
            v-for="column in activeColumns"
            :key="`title-${column.title}`">
            {{ column.title }}
          </md-table-head>
        </md-table-row>
        <md-table-row
          v-for="review in reviews"
          :key="review._id">
          <md-table-cell
            v-for="column in activeColumns"
            :key="`row-${column.title}`">
            <div
              v-if="column.title == 'Buttons'"
              class="btn-wrapper">
              <router-link
                class="btn btn-activate btn-small"
                :to="{ name: 'AdminPVReview', params: { reviewId: review._id } }">Arrows</router-link>
              <a
                class="btn btn-transparent btn-small"
                :href="`${windowOrigin}/admin/pv/reviews/${review._id}`"
                target="_blank">Tab</a>
            </div>
            <div v-else-if="!column.exclude">
              {{ getValue(review, column) }}
              <!--<span v-if="column.edit" @click="openEditModal(review, column.db)">
                <svg style="vertical-align: top; cursor: pointer;" width="12px" height="12px" viewBox="0 0 24 24" id="_24x24_On_Light_Edit" data-name="24x24/On Light/Edit" xmlns="http://www.w3.org/2000/svg">
                  <rect id="view-box" width="24" height="24" fill="none"/>
                  <path id="Shape" d="M.75,17.5A.751.751,0,0,1,0,16.75V12.569a.755.755,0,0,1,.22-.53L11.461.8a2.72,2.72,0,0,1,3.848,0L16.7,2.191a2.72,2.72,0,0,1,0,3.848L5.462,17.28a.747.747,0,0,1-.531.22ZM1.5,12.879V16h3.12l7.91-7.91L9.41,4.97ZM13.591,7.03l2.051-2.051a1.223,1.223,0,0,0,0-1.727L14.249,1.858a1.222,1.222,0,0,0-1.727,0L10.47,3.91Z" transform="translate(3.25 3.25)" fill="#141124"/>
                </svg>
              </span>-->
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <!--<md-table-row>-->
      <div class="pagination">
        <button
          :class="{ 'btn-disabled': first == 0 }"
          class="btn btn-activate"
          @click="paginate(-1)">
          Previous
        </button>
        <div style="align-self: center;">Page {{ first == 0 ? '1' : (first / number)+1 }}</div>
        <button
          :class="{ 'btn-disabled': reviews.length < 50 }"
          class="btn btn-activate"
          @click="paginate(1)">
          Next
        </button>
      </div>
    </div>

    <modal
      v-if="showListModal"
      size="xlarge"
      modalType="fullscreen"
      @close="showListModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get List' }">
      <slot>
        <h3>Filters</h3>
        <md-datepicker
          v-model="statCreatedStart">
          <label>Created Start</label>
        </md-datepicker>
        <md-datepicker
          v-model="statCreatedEnd">
          <label>Created End</label>
        </md-datepicker>
        <!--<md-field>
          <label for="administrated">administrated?</label>
          <md-select
            v-model="administrated">
            <md-option value="all">Both</md-option>
            <md-option :value="true">administrated == true</md-option>
            <md-option :value="false">administrated == false</md-option>
          </md-select>
        </md-field>
        <h3>Change selection</h3>
        <md-field>
          <label for="administrated">Set Administrated</label>
          <md-select
            v-model="setAdministrated">
            <md-option value="all">Set all</md-option>
            <md-option value="cta_done">Set all (cta_done == true)</md-option>
            <md-option :value="false">Don't set</md-option>
          </md-select>
        </md-field>-->
        <div>
          <button class="btn btn-activate" @click="getList()">Download List</button>
          <button class="btn btn-transparent" @click="showListModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <!--<modal
      v-if="show3rdPartyListModal"
      size="xlarge"
      modalType="fullscreen"
      @close="show3rdPartyListModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get 3rd party List' }">
      <slot>
        <div style="text-align: left;">
          <h3>Important!</h3>
          <p>When you download this list, the following will happen:</p>
          <ul>
            <li>All reviews will be administrated == false</li>
            <li>The reviews you get will be set to administrated = true</li>
          </ul>
          <p>All of this means that the next time you click this button, you will only get reviews that are administrated == false, ie repeat the steps above</p>
        </div>
        <div>
          <button class="btn btn-activate" @click="get3rdPartyList()">Download List</button>
          <button class="btn btn-transparent" @click="show3rdPartyListModal = false">Close</button>
        </div>
      </slot>
    </modal>-->

    <modal
      v-if="dateModal"
      size="xlarge"
      modalType="fullscreen"
      @close="dateModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Set filter for created-date' }">
      <slot>
        <md-datepicker
          v-model="createdStart">
          <label>Created Start</label>
        </md-datepicker>
        <md-datepicker
          v-model="createdEnd">
          <label>Created End</label>
        </md-datepicker>
        <div>
          <button class="btn btn-activate" @click="applyDateFilter()">Apply</button>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import moment, { unix } from 'moment-timezone';
import { debounce as _debounce } from 'lodash-es';
import InputField from '../stories/components/InputField/InputField';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: "AdminPVReviews",
  components: {
    InputField,
  },
  mixins: [BeforeEnterMixin],
  created() {
    this.findAdminPVReviews = _debounce(() => {
      this.$store.dispatch('pvReviews/findAdminPVReviews', {
        key: this.key,
        search: this.search,
        filters: this.getActiveFilters(),
        activationId: this.activationId,
        createdStart: this.createdStart/1000,
        createdEnd: this.createdEnd/1000 + 3600*24,
        first: this.first,
        number: this.number,
      }).then(() => {
        this.loadedReviews = true;
      });
    }, 300);

    this.findAdminPVActivation = _debounce(() => {
      this.$store.dispatch('pvActivations/findAdminPVActivation', {
        query: this.actSearch,
      }).then(() => {
        this.loadedActivations = true;
      });
    }, 300);
  },
  beforeUnmount() {
    this.findAdminPVReviews.cancel();
    this.findAdminPVActivation.cancel();
  },
  mounted() {
    this.setActiveSearch();
    this.setActiveColumns();
    this.setActiveStatus();
    this.setActiveFilters();
    this.setActivationId();

    this.findAdminPVReviews();
    this.findAdminPVActivation();
  },
  computed: {
    ...mapGetters('pvActivations', ['activations']),
    ...mapGetters('pvReviews', ['reviews']), // , 'reviewsTotal'
    loadedPage() {
      return this.loadedActivations && this.loadedReviews;
    },
    activeColumns() {
      return this.columns.filter((column) => {
        return column.active;
      });
    },
    activationPicker() {
      let activationPicker = [];
      if (this.activations && this.activations.length) {
        for (let i = 0; i < this.activations.length; i++) {
          const activation = this.activations[i];
          activationPicker.push(activation);
          if (activation._id == this.activationId) {
            activationPicker[activationPicker.length-1].active = true;
          }
        }
      }
      activationPicker.sort(function (a, b) {
        if (a.company.name < b.company.name) {
          return -1;
        }
        if (a.company.name > b.company.name) {
          return 1;
        }
        return 0;
      });
      return activationPicker;
    },
  },
  data() {
    return {
      loadedReviews: false,
      loadedActivations: false,
      statusOptions: false,
      filterOptions: false,
      columnOptions: false,
      activationOptions: false,
      statOptions: false,
      dateModal: false,
      key: '',
      search: '',
      actSearch: '',
      retSearch: '',
      statuses: [
        { id: 'placeholder', name: 'Created by Enginio', active: false, filter: { status: 'CREATED_ENGINIO' }},
        { id: 'reviewconfirmed', name: 'Review confirmed', active: false, filter: { status: 'APPROVED' }},
      ],
      filters: [
        { id: 'datemodal', name: 'Date', active: true,
          func: () => {
            this.dateModal = !this.dateModal;
          }
        },
      ],
      activationId: '',
      first: 0,
      number: 50,
      columns: [
        /*{ title: 'Buttons', db: 'buttons', active: true },*/
        { title: 'Comp name', active: true, db: 'activation.company.name', type: 'string' },
        { title: 'Activation name', active: true, db: 'activation.name', type: 'string' },
        { title: 'Activation id', active: false, db: 'activation._id', type: 'string' },
        { title: 'Review id', active: true, db: '_id', type: 'string' },
        { title: 'Name', active: true, db: 'name+last_name', type: 'string' },
        { title: 'Phone Nr', active: true, db: 'phone_nr', type: 'string' },
        { title: 'Email', active: false, db: 'email', type: 'string' },
        { title: 'partner', active: true, db: 'partner', type: 'string' },
        { title: 'item', active: false, db: 'item', type: 'string' },
        { title: 'Partner review id', active: false, db: 'review_id', type: 'string' },
        { title: 'Moderation status', active: false, db: 'review_status', type: 'string' },
        { title: 'Status', active: true, db: 'status', type: 'string' },
        { title: 'Status code', active: false, db: 'status_code', type: 'string' },
        { title: 'Confirmed', active: false, db: 'confirmed', type: 'boolean' },
        { title: 'Modelnr', active: false, db: 'modelnr', type: 'string' },
        { title: 'Product', active: false, db: 'product', type: 'string' },
        { title: 'Title', active: false, db: 'title', type: 'string' },
        { title: 'Nickname', active: false, db: 'nickname', type: 'string' },
        { title: 'Description', active: false, db: 'description', type: 'string' },
        { title: 'Rating', active: false, db: 'rating', type: 'number' },
        { title: 'Text', active: false, db: 'text', type: 'string' },
        { title: 'Comments', active: false, type: 'array', db: 'comments', value: 'comment' },
        { title: 'Created_at', active: false, type: 'unix', db: 'created_at' },
        { title: 'Updated_at', active: false, type: 'unix', db: 'updated_at' },
      ],
      editId: '',
      editValue: '',
      editKey: '',
      editModal: false,
      showListModal: false,
      show3rdPartyListModal: false,
      statCreatedStart: 1672578061000,
      statCreatedEnd: (new moment()).valueOf(),
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
      administrated: 'all',
      setAdministrated: false,
      won: 'all',
    };
  },
  watch: {
    search() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      if (!this.search) {
        delete query.search;
      } else {
        query.search = this.search;
      }
      this.$router.replace({ query });
    },
    columns: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.col = [];
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (!column.exclude && column.active) {
            query.col.push(column.db);
          }
        }
        this.$router.replace({ query });
        this.findAdminPVReviews();
      },
      deep: true
    },
    statuses: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.stat = [];
        for (let i = 0; i < this.statuses.length; i++) {
          const stat = this.statuses[i];
          if (stat.active) {
            query.stat.push(stat.id);
          }
        }
        this.$router.replace({ query });
        this.resetPagination();
        this.findAdminPVReviews();
      },
      deep: true
    },
    filters: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.filt = [];
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filter.active && !filter.func) {
            query.filt.push(filter.id);
          }
        }
        this.$router.replace({ query });
        this.resetPagination();
        this.findAdminPVReviews();
      },
      deep: true
    },
    activationId() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.acti = this.activationId;
      this.$router.replace({ query });
      this.resetPagination();
      this.findAdminPVReviews();
    },
  },
  methods: {
    setActiveSearch() {
      const { search } = this.$route.query;
      this.search = search;
    },
    setActiveColumns() {
      const { col } = this.$route.query;
      if (col && col.length) {
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (col.includes(column.db)) {
            column.active = true;
          } else {
            column.active = false;
          }
        }
      }
    },
    setActiveStatus() {
      const { stat } = this.$route.query;
      if (stat && stat.length) {
        for (let i = 0; i < this.statuses.length; i++) {
          const status = this.statuses[i];
          if (stat.includes(status.id)) {
            status.active = true;
          } else {
            status.active = false;
          }
        }
      }
    },
    setActiveFilters() {
      const { filt } = this.$route.query;
      const { created_at_start, created_at_end } = this.$route.query;
      this.createdStart = created_at_start ? Number(created_at_start) : 1672578061000;
      this.createdEnd = created_at_end ? Number(created_at_end) : (new moment()).valueOf();
      if (filt && filt.length) {
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filt.includes(filter.id)) {
            filter.active = true;
          } else {
            filter.active = false;
          }
        }
      }
    },
    setActivationId() {
      const { acti } = this.$route.query;
      this.activationId = acti;
    },
    getActiveFilters() {
      let realFilters = {};
      for (let i = 0; i < this.statuses.length; i++) {
        const status = this.statuses[i];
        if (status.active) {
          Object.assign(realFilters, status.filter);
        }
      }
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i];
        if (filter.active) {
          Object.assign(realFilters, filter.filter);
        }
      }
      return realFilters;
    },
    activateStatusFilter(status) {
      for (let i = 0; i < this.statuses.length; i++) {
        this.statuses[i].active = false;
      }
      if (this.statusId != status.id) {
        status.active = true;
        this.statusId = status.id;
      } else {
        status.active = false;
        this.statusId = '';
      }
    },
    activateActivationFilter(activation) {
      for (let i = 0; i < this.activationPicker.length; i++) {
        this.activationPicker[i].active = false;
      }
      if (this.activationId != activation._id) {
        activation.active = true;
        this.activationId = activation._id;
      } else {
        activation.active = false;
        this.activationId = '';
      }
    },
    getValue(review, column) {
      if (column.type == 'array') {
        let result = [];
        for (let i = 0; i < review[column.db].length; i++) {
          const element = review[column.db][i];
          result.push(element[column.value])
        }
        return result.join(' && ');
      }
      const lastValue = this.getLastValue(review, column.db);
      if (column.type == 'boolean') {
        return lastValue == 'undefined' ? false : lastValue == 'true';
      } else if (column.type == 'number-floor') {
        return Math.floor(Number(lastValue)*1000)/1000;
      } else if (column.type == 'unix') {
        return moment.unix(lastValue).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return lastValue == 'undefined' ? '' : lastValue;
      }
    },
    getLastValue(review, db) {
      const dbSeveralKeys = db.split('+');
      let lastValue = '';
      for (let j = 0; j < dbSeveralKeys.length; j++) {
        const dbSubKey = dbSeveralKeys[j];
        const dbKeys = dbSubKey.split('.');
        try {
          let result = review[dbKeys[0]];
          if (dbKeys.length > 1) {
            for (let i = 1; i < dbKeys.length; i++) {
              result = result[dbKeys[i]];
            }
          }
          if (typeof result == 'object') {
            result = JSON.stringify(result);
          }
          lastValue += `${result}${db.includes('+') ? ' ': ''}`;
        } catch (error) {
          console.error('getLastValue', error, review, db, dbSubKey, dbKeys);
        }
      }
      return lastValue || '';
    },
    resetPagination() {
      this.first = 0;
      this.number = 50;
    },
    paginate(index) {
      this.first = this.first + (this.number*index);
      this.findAdminPVReviews();
    },
    applyDateFilter() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.created_at_start = this.createdStart;
      query.created_at_end = this.createdEnd;
      this.$router.replace({ query });
      this.findAdminPVReviews();
    },
    // TODO: add the search in the lists below
    getList() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/reviews/csv`
        +`?createdStart=${this.statCreatedStart/1000}&createdEnd=${this.statCreatedEnd/1000}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.reviews[0]?.activation.name || this.activationId}_reviews_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.md-table-row {
  .md-table-cell {
    border: 1px solid #ebebeb;
  }
}
.btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  .btn-small {
    height: 20px;
    font-size: 10px;
    padding: 1px;
  }
}
.pagination {
  background-color: rgba(255, 255, 255, .5);
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 10px 20px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$active-color: rgb(162, 166, 204);

.active-filter {
  background: $active-color;
}

.column-picker {
  position: relative;
  max-width: 300px;
  .filter-button {
    width: 100%;
  }
  .column-options {
    position: absolute;
    background: white;
    border: 1px solid grey;
    bottom: 0px;
    transform: translateY(100%);
    width: 100%;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    
    .column-search, .column-option {
      padding: 6px;
    }
    .column-option {
      margin: 5px 0;
      cursor: pointer;
      &:hover{
        background: rgb(230, 230, 230);
      }
    }
    .active-column-option {
      background: $active-color;
    }
  }
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  height: calc(100vh - 70px - 48px);
  .list-toolbar {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr;
  }
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .review-item-image{
      width: fit-content;
      height: fit-content;
    }
    .review-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.review-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-review-button{
  z-index: 1000;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}

.admin-entity--subentity {
  input {
    width: 90%;
  }

  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .subentity {
    padding: 0 50px 40px 50px;
  }

  .admin-entity--title {
    padding: 20px 50px;
  }
}
</style>
